//src/component/userlogin.js
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useAuth } from "../../context/AuthContext";
import api from "../../api";
import { useLogin } from "../../hooks/useLogin";
import {
  convertPhoneNumber,
  CountryCodeWithString,
} from "../../utils/phoneUtils";

const extractUuid = (guestToken) => {
  return guestToken.split("_")[1];
};
const UserLogin = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      localStorage.getItem("userUid") &&
      localStorage.getItem("LoggedIn") &&
      localStorage.getItem("userRole") === "shopper"
    )
      navigate("/");
  });
  const [countryCode, setCountryCode] = useState("us");
  const [dialCode, setDialCode] = useState("+1");
  const [phone, setPhone] = useState("");
  const [isOTPSent, setIsOTPSent] = useState(false);
  const { login } = useAuth();
  const [activeTab, setActiveTab] = useState("login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOtpField, setShowOtpField] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [showConfirmPasswordField, setShowConfirmPasswordField] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [errorData, setError] = useState(null);
  const [agreeTerms, setAgreeTerms] = useState(true);
  const [otpVerified, setOtpVerified] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timer, setTimer] = useState(60);
  const [successMessage, setSuccessMessage] = useState("");
  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
    setPhone("");
    setEmail("");
    setPassword("");
    setOtp("");
    setNewPassword("");
    setConfirmPassword("");
    setShowOtpField(false);
    setShowPasswordField(false);
    setShowConfirmPasswordField(false);
    setError(null);
    setOtpVerified(false);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    if (otp) {
      try {
        const device_token = localStorage.getItem("apiToken");
        const uuid = extractUuid(device_token);

        const trimmedValue = phone.startsWith(dialCode)
          ? phone.substring(dialCode.length)
          : phone;
        const username =
          email || (trimmedValue ? countryCode + "-" + trimmedValue : "");
        const country_code = phone ? countryCode.toUpperCase() : "US";
        const clientId = localStorage.getItem("clientId");
        const otpResponse = await api.post("/user/login", {
          otp,
          username,
          // country_code: country_code,
          country_code: localStorage.getItem("countryCode") || "US",

          device_token: clientId,
          device_type: "web",
          app_version: "1.0",
          guest_api_token: device_token,
          password: "",
        });

        if (
          otpResponse.data &&
          otpResponse.data.api_token &&
          otpResponse.data.status === 200 &&
          otpResponse.data.message === "success"
        ) {
          localStorage.setItem("apiToken", otpResponse.data.api_token);
          localStorage.setItem("userRole", otpResponse.data.role);
          localStorage.setItem("userName", otpResponse.data.name);
          localStorage.setItem("userEmail", otpResponse.data.email);
          localStorage.setItem("userUid", otpResponse.data.user_uid);
          localStorage.setItem("LoggedIn", true);
          login(otpResponse.data.api_token, otpResponse.data.role);
          navigate("/");
        } else {
          setError("OTP verification failed.");
        }
      } catch (err) {
        console.error("OTP confirmation error:", err);
        setError("Failed to confirm OTP.");
      } finally {
        setLoading(false);
      }
    } else if (password) {
      try {
        const device_token = localStorage.getItem("apiToken");
        const trimmedValue = phone.startsWith(dialCode)
          ? phone.substring(dialCode.length)
          : phone;
        const username =
          email || (trimmedValue ? countryCode + "-" + trimmedValue : "");
        const country_code = phone ? countryCode.toUpperCase() : "US";

        if (!username) {
          console.error("Error: Both email and phone are empty.");
          setError("Error: Both email and phone are empty.");
          return;
        } else {
          const checkUserResponse = await api.post("/user/check_user", {
            username: username,
          });

          const clientId = localStorage.getItem("clientId");
          if (checkUserResponse.data.status === 200) {
            const response = await api.post("/user/login", {
              username,
              password,
              app_version: "1.0",
              device_type: "web",
              device_token: clientId,
              country_code,
            });

            if (response.data && response.data.api_token) {
              localStorage.setItem("apiToken", response.data.api_token);
              localStorage.setItem("userRole", response.data.role);
              localStorage.setItem("userName", response.data.name);
              localStorage.setItem("userUid", response.data.user_uid);
              localStorage.setItem("userEmail", response.data.email);
              localStorage.setItem("LoggedIn", true);
              login(response.data.api_token, response.data.role);

              navigate("/");
            } else {
              setError(response.data.message);
            }
          } else if (
            checkUserResponse.data.status === 404 &&
            checkUserResponse.data.message === "User not found"
          ) {
            setError(
              "Your account doesn't exist with this phone or email. Please sign up first, then try again."
            );
          } else {
            throw new Error("Unexpected response from server.");
          }
        }
      } catch (err) {
        console.error("Login error:", err);
        setError("Login failed.");
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setError("Please enter either an OTP or a password.");
    }
  };

  const checkUser = async (username) => {
    try {
      const response = await api.post("/user/check_user", { username });
      return response.data;
    } catch (error) {
      console.error("Error checking user:", error);
      throw new Error("Failed to check user. Please try again.");
    }
  };
  const handleGetOtp = async (e) => {
    if (e && e.preventDefault) e.preventDefault();

    try {
      let response;

      const trimmedValue = phone.startsWith(dialCode)
        ? phone.substring(dialCode.length)
        : phone;
      const username =
        email || (trimmedValue ? countryCode + "-" + trimmedValue : "");
      const country_code = phone ? countryCode : "US";
      if (!username) {
        console.error("Error: Both email and phone are empty.");
        setError("Error: Both email and phone are empty.");
        return;
      }

      const checkUserResponse = await checkUser(username);
      if (activeTab === "login") {
        if (checkUserResponse.status === 200) {
          response = await api.post("/user/otp_login", {
            email: email || "",
            phone: phone ? countryCode.toUpperCase() + "-" + trimmedValue : "",
            verify_type: phone ? "phone" : "email",
          });
          setError(null);
          setShowPasswordField(false);
          setShowOtpField(true);
        } else if (
          checkUserResponse.status === 404 &&
          checkUserResponse.message === "User not found"
        ) {
          setError(
            "Your account doesn't exist with this phone or email. Please sign up first, then try again."
          );
        } else {
          throw new Error("Unexpected response from server.");
        }
      } else if (activeTab === "signup") {
        if (
          checkUserResponse.status === 404 &&
          checkUserResponse.message === "User not found"
        ) {
          const device_token = localStorage.getItem("apiToken");
          let device_token_new;
          if (device_token && !device_token.startsWith("guest_")) {
            device_token_new = `guest_${device_token}`;
            localStorage.setItem("apiToken", device_token_new);
          }

          const trimmedValue = phone.startsWith(dialCode)
            ? phone.substring(dialCode.length)
            : phone;
          const username =
            email || (trimmedValue ? countryCode + "-" + trimmedValue : "");
          const country_code = phone ? countryCode : "US";
          response = await api.post("/user/signup", {
            email: email || "",
            phone: phone ? countryCode.toUpperCase() + "-" + trimmedValue : "",
            // country_code: phone ? country_code.toUpperCase() : "US",
            country_code: localStorage.getItem("countryCode") || "US",
            guest_token: device_token_new,
            password: "",
            referral_code: "",
            gender: "",
            role: "shopper",
            name: "",
          });
          setShowOtpField(true);
          setError(null);
        } else if (checkUserResponse.status === 200) {
          setError(
            "Your account already exists with this phone or email. Please sign in."
          );
        } else {
          throw new Error("Unexpected response from server.");
        }
      }
    } catch (err) {
      console.error("OTP request error:", err);
      setError("Failed to get OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    try {
      let device_token = localStorage.getItem("apiToken");

      if (device_token && !device_token.startsWith("guest_")) {
        device_token = `guest_${device_token}`;
        localStorage.setItem("apiToken", device_token);
      }

      if (!showPasswordField) {
        const country_code = phone ? countryCode.toUpperCase() : "US";

        const trimmedValue = phone.startsWith(dialCode)
          ? phone.substring(dialCode.length)
          : phone;
        const username =
          email || (trimmedValue ? countryCode + "-" + trimmedValue : "");
        const clientId = localStorage.getItem("clientId");
        const otpResponse = await api.post("/user/login", {
          otp,
          username,
          app_version: "1.0",
          country_code,
          device_token: clientId,
          device_type: "web",
          guest_api_token: device_token,
          password: "",
        });

        if (
          otpResponse.data?.status === 200 &&
          otpResponse.data?.message === "success"
        ) {
          handleSuccessfulLogin(otpResponse.data);
        } else {
          setShowOtpField(true);
          setError("OTP verification failed.");
        }
      } else if (newPassword && confirmPassword && otp) {
        const user_uid = localStorage.getItem("user_uuid");
        const country_code = phone ? countryCode : "US";

        const trimmedValue = phone.startsWith(dialCode)
          ? phone.substring(dialCode.length)
          : phone;
        const username =
          email || (trimmedValue ? countryCode + "-" + trimmedValue : "");
        const otpConfirmResponse = await api.post("/user/confirm_otp", {
          otp,
          email: email || "",
          phone: phone ? countryCode.toUpperCase() + "-" + trimmedValue : "",
          user_uuid: user_uid,
          verify_type: phone ? "phone" : "email",
        });

        if (
          otpConfirmResponse.data?.status === 200 &&
          otpConfirmResponse.data?.message === "OTP verified"
        ) {
          await handlePasswordLogin(newPassword, device_token);
        } else {
          setError("OTP verification failed.");
        }
      } else if (newPassword && confirmPassword) {
        if (newPassword === confirmPassword) {
          await handlePasswordSignup(device_token);
        } else {
          setError("Passwords do not match.");
        }
      }
    } catch (err) {
      console.error("Sign-up error:", err);
      setError("Sign-up failed.");
    } finally {
      setLoading(false);
    }
  };
  const handleResendOTP = async (e) => {
    if (e && e.preventDefault) e.preventDefault();

    if (isButtonDisabled) {
      return;
    }

    setIsButtonDisabled(true);
    setTimer(60);

    const interval = setInterval(() => {
      clearInterval(interval);
      setIsButtonDisabled(false);
    }, 1000);

    try {
      await handleGetOtp();

      setSuccessMessage("OTP has been resent successfully!");
      setError("");
      setTimeout(() => {
        setSuccessMessage("");
      }, 30000);
    } catch (err) {
      setError("Failed to resend OTP. Please try again.");
      setSuccessMessage("");
    }
  };
  const handleSuccessfulLogin = (data) => {
    localStorage.setItem("apiToken", data.api_token);
    localStorage.setItem("userRole", data.role);
    localStorage.setItem("userName", data.name);
    localStorage.setItem("userEmail", data.email);
    localStorage.setItem("userUid", data.user_uid);
    localStorage.setItem("LoggedIn", true);
    login(data.api_token, data.role);

    setError(null);
    navigate("/");
  };

  const handlePasswordLogin = async (password, device_token) => {
    const country_code = phone ? countryCode : "US";
    setShowPasswordField(false);
    setPasswordMatch(false);
    const trimmedValue = phone.startsWith(dialCode)
      ? phone.substring(dialCode.length)
      : phone;
    const username =
      email || (trimmedValue ? countryCode + "-" + trimmedValue : "");
    const clientId = localStorage.getItem("clientId");
    const loginResponse = await api.post("/user/login", {
      otp: "",
      username,
      country_code,
      device_token: clientId,
      device_type: "web",
      app_version: "1.0",
      guest_api_token: device_token,
      password,
    });

    if (
      loginResponse.data?.status === 200 &&
      loginResponse.data?.message === "success"
    ) {
      handleSuccessfulLogin(loginResponse.data);
    } else {
      setError("OTP verification failed.");
    }
  };

  const handlePasswordSignup = async (device_token) => {
    const country_code = phone ? countryCode : "US";

    const trimmedValue = phone.startsWith(dialCode)
      ? phone.substring(dialCode.length)
      : phone;
    const username =
      email || (trimmedValue ? countryCode + "-" + trimmedValue : "");
    const signupResponse = await api.post("/user/signup", {
      email: email || "",
      phone: phone ? countryCode.toUpperCase() + "-" + trimmedValue : "",
      // country_code: phone ? country_code.toUpperCase() : "US",
      country_code: localStorage.getItem("countryCode") || "US",
      guest_token: device_token,
      password: newPassword,
      referral_code: "",
      gender: "",
      role: "shopper",
      name: "",
    });

    if (signupResponse.data?.status === 400) {
      setError(signupResponse.data.message);
    } else {
      setShowOtpField(true);
      setPasswordMatch(true);
      setError("");
      setShowPasswordField(false);
      localStorage.setItem("user_uuid", signupResponse.data.user_uuid);
    }
  };

  const toggleOtpField = () => {
    setShowOtpField(true);
    setShowPasswordField(false);
    setShowConfirmPasswordField(false);
  };

  const togglePasswordField = () => {
    setShowOtpField(false);
    setShowPasswordField(true);
    setShowConfirmPasswordField(true);
    setOtpVerified(true);
  };
  const SignupPasswordField = async () => {
    setShowPasswordField(false);
    setShowOtpField(false);
    setPasswordMatch(false);

    const trimmedEmail = email?.trim() || "";
    const trimmedPhone = phone || "";

    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const isValidPhone = (phone) => {
      return phone.length > 5;
    };

    if (trimmedEmail && trimmedPhone) {
      console.error("Error: Both email and phone are provided.");
      setError(
        "Error: Please provide either an email or a phone number, not both."
      );
      return;
    }

    const trimmedValue = phone.startsWith(dialCode)
      ? phone.substring(dialCode.length)
      : phone;
    const username =
      trimmedEmail || (trimmedValue ? countryCode + "-" + trimmedValue : "");

    if (!username) {
      console.error("Error: Both email and phone are empty.");
      setError("Error: Both email and phone are empty.");
      return;
    }

    if (trimmedEmail && !isValidEmail(trimmedEmail)) {
      console.error("Error: Invalid email format.");
      setError("Error: Invalid email format.");
      return;
    } else if (trimmedPhone && !isValidPhone(trimmedPhone)) {
      console.error("Error: Phone number must be longer than 5 characters.");
      setError("Error: Phone number must be at least 6 characters long.");
      return;
    }

    try {
      const checkUserResponse = await checkUser(username);
      if (
        checkUserResponse.status === 404 &&
        checkUserResponse.message === "User not found"
      ) {
        setShowOtpField(false);
        setShowPasswordField(true);
        // User does not exist, show an error message
        setError("");
      } else {
        setShowOtpField(false);
        setShowPasswordField(false);

        setError(
          "Your account already exist with this phone or email. Please sign in to continue."
        );
      }
    } catch (error) {
      console.error("Error checking user: ", error);
      setError("Failed to check user. Please try again.");
    }
  };

  const checkUserPasswordField = async () => {
    const trimmedEmail = email?.trim() || "";
    const trimmedPhone = phone || "";

    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const isValidPhone = (phone) => {
      return phone.length > 5;
    };

    if (trimmedEmail && trimmedPhone) {
      console.error("Error: Both email and phone are provided.");
      setError(
        "Error: Please provide either an email or a phone number, not both."
      );
      return;
    }

    const trimmedValue = phone.startsWith(dialCode)
      ? phone.substring(dialCode.length)
      : phone;
    const username =
      trimmedEmail || (trimmedValue ? countryCode + "-" + trimmedValue : "");

    if (!username) {
      console.error("Error: Both email and phone are empty.");
      setError("Error: Both email and phone are empty.");
      return;
    }

    if (trimmedEmail && !isValidEmail(trimmedEmail)) {
      console.error("Error: Invalid email format.");
      setError("Error: Invalid email format.");
      return;
    } else if (trimmedPhone && !isValidPhone(trimmedPhone)) {
      console.error("Error: Phone number must be longer than 5 characters.");
      setError("Error: Phone number must be at least 6 characters long.");
      return;
    }

    try {
      const checkUserResponse = await checkUser(username);
      if (
        checkUserResponse.status === 404 &&
        checkUserResponse.message === "User not found"
      ) {
        setShowOtpField(false);
        setShowPasswordField(false);
        setError(
          "Your account doesn't exist with this phone or email. Please sign up first, then try again."
        );
      } else {
        setShowOtpField(false);
        setShowPasswordField(true);
        setError("");
      }
    } catch (error) {
      console.error("Error checking user: ", error);
      setError("Failed to check user. Please try again.");
    }
  };

  const handlePhoneChange = (value, countryData) => {
    const currentDialCode = countryData.dialCode;
    setPhone(value);
    setCountryCode(countryData.countryCode);
    setDialCode(currentDialCode);
    setEmail("");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setPhone("");
  };

  const handleCheckboxChange = () => {
    setAgreeTerms(!agreeTerms);
  };
  const checkConfirmPassword = async (e) => {
    e.preventDefault();

    if (!newPassword || !confirmPassword) {
      setError("Please enter both new password and confirm password.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      let device_token = localStorage.getItem("apiToken");

      if (device_token && !device_token.startsWith("guest_")) {
        device_token = `guest_${device_token}`;
        localStorage.setItem("apiToken", device_token);
      }

      if (newPassword && confirmPassword) {
        await handlePasswordSignup(device_token);
      }
    } catch (err) {
      console.error("Sign-up error:", err);
      setError("Sign-up failed.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="wrapper">
      <div className="signin-block">
        <div className="container">
          <div className="tabs">
            <ul id="tabs-nav">
              <li className={activeTab === "login" ? "active" : ""}>
                <button onClick={() => handleTabSwitch("login")}>
                  Sign in
                </button>
              </li>
              <li className={activeTab === "signup" ? "active" : ""}>
                <button onClick={() => handleTabSwitch("signup")}>
                  Sign Up
                </button>
              </li>
            </ul>
            <div id="tabs-content">
              {activeTab === "login" && (
                <div id="tab1" className="tab-content">
                  <form onSubmit={handleLogin} autoComplete="off">
                    <div className="input-row">
                      <PhoneInput
                        country={"us"}
                        value={phone}
                        onChange={handlePhoneChange}
                        inputStyle={{ width: "100%" }}
                        disabledCountryCode
                        inputProps={{
                          name: `phone_${Math.random()}`,
                          id: `phone_${Math.random()}`,
                          autoComplete: "off",
                        }}
                      />
                    </div>
                    <span>or</span>
                    <div className="input-row">
                      <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailChange}
                        name={`email_${Math.random()}`}
                        id={`email_${Math.random()}`}
                        autoComplete="off"
                      />
                    </div>
                    <div className="btn-row">
                      <ul>
                        <li>
                          <button type="button" id="otp" onClick={handleGetOtp}>
                            Get OTP
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            onClick={checkUserPasswordField}
                          >
                            Enter Password
                          </button>
                        </li>
                      </ul>
                    </div>
                    {showOtpField && (
                      <>
                        <div className="input-row otp">
                          <input
                            type="number"
                            placeholder="Enter OTP Here"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            name={`otp_${Math.random()}`}
                            id={`otp_${Math.random()}`}
                            autoComplete="off"
                          />
                        </div>
                        <div>
                          {errorData && (
                            <p style={{ color: "red" }}>{errorData}</p>
                          )}{" "}
                          {successMessage && (
                            <p style={{ color: "#038dce" }}>{successMessage}</p>
                          )}{" "}
                          {isButtonDisabled ? (
                            <p>Resend OTP available in {timer} seconds</p>
                          ) : (
                            <a
                              onClick={handleResendOTP}
                              disabled={isButtonDisabled}
                              className="forgot-pass"
                            >
                              Resend OTP
                            </a>
                          )}
                        </div>
                        <div className="input-submit">
                          <input
                            type="submit"
                            value={loading ? "Logging in..." : "Sign in"}
                            disabled={loading}
                          />
                        </div>
                      </>
                    )}
                    {showPasswordField && (
                      <>
                        <div className="input-row">
                          <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            name={`password_${Math.random()}`}
                            id={`password_${Math.random()}`}
                            autoComplete="off"
                          />
                        </div>
                        <a href="/reset-password" className="forgot-pass">
                          Forgot password?
                        </a>
                        <div className="input-submit">
                          <input
                            type="submit"
                            value={loading ? "Logging in..." : "Sign in"}
                            disabled={loading}
                          />
                        </div>
                      </>
                    )}

                    {errorData && (
                      <div className="error-message">{errorData}</div>
                    )}
                  </form>
                </div>
              )}
              {activeTab === "signup" && (
                <div id="tab2" className="tab-content">
                  <form onSubmit={handleSignup} autoComplete="off">
                    <div className="input-row">
                      <PhoneInput
                        country={"us"}
                        value={phone}
                        onChange={handlePhoneChange}
                        inputStyle={{ width: "100%" }}
                        disabledCountryCode
                        inputProps={{
                          name: `phone_${Math.random()}`,
                          id: `phone_${Math.random()}`,
                          autoComplete: "off",
                        }}
                      />
                    </div>
                    <span>or</span>
                    <div className="input-row">
                      <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailChange}
                        name={`email_${Math.random()}`}
                        id={`email_${Math.random()}`}
                        autoComplete="off"
                      />
                    </div>
                    <div className="btn-row">
                      <ul>
                        <li>
                          <button type="button" id="otp" onClick={handleGetOtp}>
                            Get OTP
                          </button>
                        </li>
                        <li>
                          <button type="button" onClick={SignupPasswordField}>
                            Enter Password
                          </button>
                        </li>
                      </ul>
                    </div>
                    {showOtpField && (
                      <>
                        <div className="input-row otp">
                          <input
                            type="number"
                            placeholder="123456"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            name={`otp_${Math.random()}`}
                            id={`otp_${Math.random()}`}
                            autoComplete="off"
                          />
                        </div>
                        <div>
                          {isButtonDisabled ? (
                            <p>Resend OTP available in {timer} seconds</p>
                          ) : (
                            <a
                              onClick={handleResendOTP}
                              disabled={isButtonDisabled}
                              className="forgot-pass"
                            >
                              Resend OTP
                            </a>
                          )}
                        </div>
                        <div className="agree-text">
                          <input
                            type="checkbox"
                            checked={agreeTerms}
                            onChange={handleCheckboxChange}
                            name={`agree_${Math.random()}`}
                            id={`agree_${Math.random()}`}
                          />
                          <label htmlFor={`agree_${Math.random()}`}>
                            By clicking Create account, I agree that I have read
                            and accepted the{" "}
                            <a href="/termsofservices" target="_blank">
                              Terms of use
                            </a>{" "}
                            and{" "}
                            <a href="/privacy-policy" target="_blank">
                              Privacy Policy
                            </a>
                          </label>
                        </div>
                        <div className="input-submit">
                          <input
                            type="submit"
                            value={loading ? "Signing up..." : "Sign Up"}
                            disabled={loading || !agreeTerms}
                          />
                        </div>
                      </>
                    )}
                    {showPasswordField && (
                      <>
                        <div className="input-row">
                          <input
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            name={`newPassword_${Math.random()}`}
                            id={`newPassword_${Math.random()}`}
                            autoComplete="off"
                          />
                        </div>
                        <div className="input-row confirm-pass">
                          <input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            name={`confirmPassword_${Math.random()}`}
                            id={`confirmPassword_${Math.random()}`}
                            autoComplete="off"
                          />
                        </div>
                        <div className="agree-text">
                          <input
                            type="checkbox"
                            checked={agreeTerms}
                            onChange={handleCheckboxChange}
                            name={`agree_${Math.random()}`}
                            id={`agree_${Math.random()}`}
                          />
                          <label htmlFor={`agree_${Math.random()}`}>
                            By clicking Create account, I agree that I have read
                            and accepted the{" "}
                            <a href="/termsofservices" target="_blank">
                              Terms of use
                            </a>{" "}
                            and{" "}
                            <a href="/privacy-policy" target="_blank">
                              Privacy Policy
                            </a>
                          </label>
                        </div>

                        {passwordMatch ? (
                          <div className="input-submit">
                            <input
                              type="submit"
                              value={loading ? "Signing up..." : "Sign Up"}
                              disabled={loading || !agreeTerms}
                            />
                          </div>
                        ) : (
                          <div className="input-submit proceed_signup">
                            <button
                              type="button"
                              onClick={checkConfirmPassword}
                            >
                              Proceed
                            </button>
                          </div>
                        )}
                      </>
                    )}

                    <div className="signin-block">
                      <div className="container">
                        {errorData ? (
                          <p style={{ color: "red" }}>{errorData}</p>
                        ) : successMessage ? (
                          <p style={{ color: "#038dce" }}>{successMessage}</p>
                        ) : null}
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLogin;
