import React, { createContext, useState, useEffect, useCallback } from "react";
import api from "../api";
import { getClientIp } from "../utils/getClientIp";
import { useCart } from "./CartContext";

const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [apiToken, setApiToken] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [deviceToken, setDeviceToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const cartContext = useCart();
  const { setCartCount } = cartContext || {};

  const getClientId = useCallback(async () => {
    let clientId = localStorage.getItem("clientId");
    if (!clientId) {
      clientId = await getClientIp();
      localStorage.setItem("clientId", clientId);
    }
    return clientId;
  }, []);

  // Helper function to determine the country code based on the domain
  const getCountryCodeByDomain = () => {
    const hostname = window.location.hostname;
    const port = window.location.port;

    if (hostname === "fashionxglobal.com" || port === "3001") {
      return "US";
    } else if (hostname === "modestrend.com" || port === "3002") {
      return "TR";
    } else if (hostname === "asianethnics.com" || port === "3003") {
      return "IN";
    } else {
      return "US";
    }
  };

  useEffect(() => {
    const initializeAuth = async () => {
      setLoading(true);
      try {
        const storedToken = localStorage.getItem("apiToken");
        const storedRole = localStorage.getItem("userRole");
        const storedCurrencyCode = localStorage.getItem("currencyCode");
        const clientId = await getClientId();

        if (!storedCurrencyCode) {
          localStorage.setItem("currencyCode", "USD");
          localStorage.setItem("currencyCode", "US");
          localStorage.setItem("countryCode", "US");
          localStorage.setItem("currencySymbol", "$");
          localStorage.setItem("currencyId", "2");
        }

        if (storedToken && storedToken !== "null") {
          setApiToken(storedToken);
          setUserRole(storedRole);
          setDeviceToken(clientId);
          setLoading(false);
          return;
        }

        // Get the country code based on the domain
        const countryCode = getCountryCodeByDomain();

        const guestLoginResponse = await api.post("/guest/guest_login", {
          app_version: "1.0",
          country_code: countryCode, // Use dynamic country code here
          device_token: clientId || "",
          device_type: "web",
        });

        if (guestLoginResponse.data && guestLoginResponse.data.api_token) {
          const newApiToken = guestLoginResponse.data.api_token;
          setApiToken(newApiToken);
          setUserRole("guest");
          setDeviceToken(clientId);
          setCountryCode(countryCode);

          localStorage.setItem("apiToken", newApiToken);
          localStorage.setItem("userRole", "guest");
          localStorage.setItem("countryCode", countryCode);

          // Create a new cart for the guest user
          const cartResponse = await api.post("/cart/create_cart", {
            api_token: newApiToken,
          });

          if (cartResponse.data && cartResponse.data.cart_data) {
            const { cart_id, order_num, item_count } =
              cartResponse.data.cart_data;

            localStorage.setItem("cart_id", cart_id);
            localStorage.setItem("order_num", order_num);
            localStorage.setItem("item_count", item_count);
            localStorage.setItem("cartCount", item_count);

            if (typeof item_count === "number" && !isNaN(item_count)) {
              if (setCartCount) setCartCount(item_count);
            } else {
              console.error("Invalid item_count:", item_count);
            }
          } else {
            console.error("Cart data is missing:", cartResponse.data);
          }
        }
      } catch (err) {
        console.error("Initialization failed:", err);
        setError("Initialization failed.");
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();
  }, [getClientId, setCartCount]);

  const login = (token, role) => {
    setApiToken(token);
    setUserRole(role);
    localStorage.setItem("apiToken", token);
    localStorage.setItem("userRole", role);
  };

  const logout = async () => {
    localStorage.removeItem("apiToken");
    localStorage.removeItem("userRole");
    localStorage.removeItem("countryCode");
    setApiToken(null);
    setUserRole(null);
    setCountryCode(null);

    try {
      const clientId = await getClientId();
      const countryCode = getCountryCodeByDomain(); // Get country code on logout

      const guestLoginResponse = await api.post("/guest/guest_login", {
        app_version: "1.0",
        country_code: countryCode, // Use dynamic country code here
        device_token: clientId || "",
        device_type: "web",
      });

      if (guestLoginResponse.data && guestLoginResponse.data.api_token) {
        const newApiToken = guestLoginResponse.data.api_token;
        setApiToken(newApiToken);
        setCountryCode(countryCode);
        setUserRole("guest");
        setDeviceToken(clientId);
        localStorage.setItem("apiToken", newApiToken);
        localStorage.setItem("countryCode", countryCode);
        localStorage.setItem("userRole", "guest");

        const cartResponse = await api.post("/cart/create_cart", {
          api_token: newApiToken,
        });

        if (cartResponse.data && cartResponse.data.cart_data) {
          const { cart_id, order_num, item_count } =
            cartResponse.data.cart_data;

          localStorage.setItem("cart_id", cart_id);
          localStorage.setItem("order_num", order_num);
          localStorage.setItem("item_count", item_count);
          localStorage.setItem("cartCount", item_count);

          if (typeof item_count === "number" && !isNaN(item_count)) {
            if (setCartCount) setCartCount(item_count);
          } else {
            console.error("Invalid item_count:", item_count);
          }
        } else {
          console.error("Cart data is missing:", cartResponse.data);
        }
      }
    } catch (err) {
      console.error("Guest login after logout failed:", err);
      setError("Guest login after logout failed.");
    }
  };

  return (
    <AuthContext.Provider
      value={{ apiToken, userRole, deviceToken, loading, error, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);

export default AuthContext;
