import React, { useState, useEffect } from "react";
import useDynamicMetaTags from "./hooks/useDynamicMetaTags";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import MetaPixel from "./components/MetaPixel";
import Skeleton from "react-loading-skeleton"; // Import the skeleton loader
import "react-loading-skeleton/dist/skeleton.css"; // Import the skeleton CSS
import { AuthProvider, useAuth } from "./context/AuthContext";
import { CartProvider } from "./context/CartContext";
import { CurrencyProvider } from "./context/CurrencyContext";
import { CheckoutProvider } from "./context/CheckoutContext";
import ScrollToTop from "./components/scroll/ScrollToTop";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import AppRoutes from "./routes/Routes";
import { ToastNotification } from "./components/toastNotification/ToastNotification";
import "./App.css";
import { UtilsProvider } from "./context/UtilsContext";
import UserLocationProvider from "./context/userlocation/UserLocationProvider";
const AppContent = () => {
  useDynamicMetaTags();
  // Cache Versioning
  const cacheVersion = "1.4.0";
  const { loading, apiToken, error } = useAuth(); // Use the useAuth hook to access state and actions
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    // Cache versioning logic
    const storedVersion = localStorage.getItem("appVersion");
    if (storedVersion !== cacheVersion) {
      localStorage.clear(); // Clear cache if app version is different
      localStorage.setItem("appVersion", cacheVersion);
      window.location.reload();
    }
  }, [cacheVersion]);

  if (loading || error) {
    return null; // Hide everything during loading or when there's an error
  }
  if (!apiToken) {
    return null;
  }

  // Render the app content when the token is valid and no errors are present
  return (
    <>
      <Header />
      <main className="main-content">
        <ScrollToTop />
        {/* <MetaPixel pixelId="1084539896401020" /> */}
        <AppRoutes />
      </main>
      <Footer />
    </>
  );
};

const App = () => {
  return (
    <UserLocationProvider>
      <AuthProvider>
        <UtilsProvider>
          <CurrencyProvider>
            <CartProvider>
              <CheckoutProvider>
                <Router>
                  <div className="app-container">
                    <ToastNotification />{" "}
                    {/* This must be included in your app */}
                    <AppContent />
                  </div>
                </Router>
              </CheckoutProvider>
            </CartProvider>
          </CurrencyProvider>
        </UtilsProvider>
      </AuthProvider>
    </UserLocationProvider>
  );
};

export default App;
