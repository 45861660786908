import React, { useEffect, useState, Suspense, lazy } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api";
import { useAuth } from "../../context/AuthContext";
import { useCurrency } from "../../context/CurrencyContext";
import { useCart } from "../../context/CartContext";
import OwlCarousel from "react-owl-carousel";
import "./LiveSocial.css";
import Modal from "../modal/Modal";
import LiveSocial from "./LiveSocial";
import LatestArrivals from "./LatestArrivals";
import BestSeller from "./BestSeller";
import VisitStoreDigitally from "./VisitStoreDigitally";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { isMobile } from "react-device-detect";
import LazyLoadImage from "../LazyLoadImage";

// Lazy loading the WeddingStore component
const WeddingStore = lazy(() => import("./WeddingStore"));

const CACHE_KEY = "homePageData";
const CACHE_DURATION = 3600000;

const HomePage = ({ style }) => {
  const { logout } = useAuth();
  const { setCurrencyPreference } = useCurrency();
  const navigate = useNavigate();
  const { setCartCount } = useCart();

  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dimensions, setDimensions] = useState({
    width: "100%",
    height: "auto",
  });

  useEffect(() => {
    // Save the scroll position to localStorage on page unload or route change
    const handleBeforeUnload = () => {
      localStorage.setItem("scrollPosition", window.scrollY);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // Scroll to top when the page loads
    window.scrollTo(0, 0);

    // Restore scroll position when coming back to the page
    const savedPosition = localStorage.getItem("scrollPosition");
    if (savedPosition) {
      window.scrollTo(0, savedPosition); // Restore to the saved scroll position
    }
  }, []);

  // Adjust dimensions for mobile and desktop
  useEffect(() => {
    setDimensions(
      isMobile
        ? { width: "100%", minHeight: "580px" }
        : { width: "100%", minHeight: "630px" }
    );
  }, []);

  const settings = {
    items: 1,
    margin: 10,
    loop: true,
    autoplay: true,
    autoplayTimeout: 6000,
    dots: true,
    nav: false,
  };

  const defaultImage = "https://via.placeholder.com/150";

  const handleBannerClick = (type_id, tag_type) => {
    navigate(`/latest-arrivals/${type_id}/${tag_type}`);
  };

  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedProductLinks, setSelectedProductLinks] = useState([]);

  const openModal = (videoUrl, productLinks) => {
    const productLinksArray = Array.isArray(productLinks.product_links)
      ? productLinks.product_links
      : [productLinks.product_links];
    const transformedVideos = productLinksArray.map((link) => ({
      ...productLinks,
      product_sui: link,
    }));
    setSelectedVideo(videoUrl);
    setSelectedProductLinks(transformedVideos[0]);
  };

  const closeModal = () => {
    setSelectedVideo(null);
    setSelectedProductLinks([]);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const api_token = localStorage.getItem("apiToken") || "";
        const clientId = localStorage.getItem("clientId");
        const userRole = localStorage.getItem("userRole");

        // Adjust token for guest users
        const adjustedToken =
          userRole === "guest" && !api_token.startsWith("guest_")
            ? `guest_${api_token}`
            : api_token;
        const country_code = localStorage.getItem("countryCode") || "US";
        // Fetch home page data
        const response = await api.post("/user/user_home_web", {
          api_token: adjustedToken,
          app_type: "web",
          app_version: "1.0",
          country_code: country_code,
          advertiser_id: "",
          device_token: clientId,
        });

        if (response.data.status === 200) {
          const cartResponse = await api.post("/cart/create_cart", {
            api_token: adjustedToken,
          });

          const cartData = cartResponse.data?.cart_data;
          if (cartData) {
            const { cart_id, order_num, item_count } = cartData;
            localStorage.setItem("cart_id", cart_id);
            localStorage.setItem("order_num", order_num);
            localStorage.setItem("item_count", item_count);
            setCartCount(Number(item_count) || 0);
          }

          setData(response.data);
          localStorage.setItem(
            "userCountryCode",
            response.data.user_data.base_country_code
          );
          localStorage.setItem("userUuid", response.data.user_data.user_uuid);
        } else if (response.data.status === 401) {
          logout();
          navigate("/home");
        }
      } catch (error) {
        setError(error.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    if (localStorage.getItem("apiToken")) {
      fetchData();
    }
  }, [logout, setCartCount, navigate]);

  const handleProductClick = (sui) => {
    navigate(`/product/${sui}`);
  };

  const extractPlainText = (htmlString) => {
    // Use a DOMParser for a more robust solution
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };

  const handleBannerClick1 = (type_id, tag_type) => {
    navigate(`/latest-arrivals/${type_id}/${tag_type}`);
  };

  return (
    <div className="main-content">
      {loading ? (
        <Skeleton style={dimensions} count={1} enableAnimation={false} />
      ) : (
        data.slider_1 && (
          <section className="banner">
            <OwlCarousel
              className="banner-carousel owl-carousel owl-theme"
              {...settings}
            >
              {data.slider_1.map((banner, index) => (
                <div
                  key={index}
                  className="item"
                  onClick={() => handleBannerClick(banner.type_id, banner.type)}
                >
                  <Link to="#" className="banner-btn">
                    <LazyLoadImage
                      style={dimensions}
                      thumbnailSrc={
                        isMobile
                          ? banner.media_url_web_mobile_thumb
                          : banner.media_url_web_desk_thumb
                      }
                      fullImageSrc={
                        isMobile
                          ? banner.media_url_web_mobile
                          : banner.media_url_web_desk
                      }
                      alt={banner.heading_1 || "Banner Image"}
                      onError={(e) => (e.target.src = defaultImage)}
                    />
                  </Link>
                </div>
              ))}
            </OwlCarousel>
          </section>
        )
      )}

      {loading ? (
        <>
          <BestSeller loading />
          <Suspense fallback={<Skeleton count={1} />}>
            <WeddingStore loading />
          </Suspense>

          <LiveSocial loading />
          <VisitStoreDigitally loading />
          <LatestArrivals loading />
        </>
      ) : (
        <>
          <BestSeller products={data.bestseller} />

          {data.slider_2 && (
            <section className="wedding">
              <OwlCarousel
                className="banner-carousel owl-carousel owl-theme"
                {...settings}
              >
                {data.slider_2.map((banner, index) => (
                  <div
                    key={index}
                    className="item"
                    onClick={() =>
                      handleBannerClick(banner.type_id, banner.type)
                    }
                  >
                    <Link to="#" className="banner-btn">
                      <LazyLoadImage
                        style={dimensions}
                        thumbnailSrc={
                          isMobile
                            ? banner.media_url_web_mobile_thumb
                            : banner.media_url_web_desk_thumb
                        }
                        fullImageSrc={
                          isMobile
                            ? banner.media_url_web_mobile
                            : banner.media_url_web_desk
                        }
                        alt={banner.heading_1 || "Wedding Store Banner"}
                        onError={(e) => (e.target.src = defaultImage)}
                      />
                    </Link>
                  </div>
                ))}
              </OwlCarousel>
            </section>
          )}

          {data.live_social && (
            <section className="live">
              <div className="container">
                <h2>{data.live_social.heading}</h2>
                <div className="social-carousel">
                  {data.live_social.data.carousel.map((item, index) => (
                    <div key={index} className="item">
                      <div
                        className="img-box"
                        style={{ minHeight: isMobile ? "260px" : "444px" }}
                      >
                        <img
                          src={item.video_thumbnail_web}
                          alt={`Social Image ${index + 1}`}
                          onClick={() => openModal(item.video_url, item)}
                        />
                      </div>
                      <div className="content">
                        <h3>
                          <Link to={`/visit-store/${item.store_uuid}`}>
                            {item.store_name}
                          </Link>
                        </h3>
                        <h4>{item.video_description}</h4>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          )}

          {data.slider_3 && (
            <section className="store">
              <div className="visit-store-digitally">
                <h2>
                  {!loading && <Link to="/stores" className="read-more"></Link>}
                </h2>
                <div className="carousel-section">
                  {loading ? (
                    <Skeleton height={400} count={1} enableAnimation={false} />
                  ) : (
                    data.slider_3.length > 0 && (
                      <OwlCarousel
                        className="store-carousel owl-carousel owl-theme"
                        {...settings}
                      >
                        {data.slider_3.map((banner, index) => (
                          <div
                            key={`${index}-${banner.type_id}`}
                            className="item"
                            onClick={() =>
                              handleBannerClick1(banner.type_id, banner.type)
                            }
                          >
                            <LazyLoadImage
                              baseColor="#ffffff"
                              highlightColor="#f8f8f8"
                              enableAnimation={false}
                              style={dimensions}
                              thumbnailSrc={
                                isMobile
                                  ? banner.media_url_web_mobile_thumb
                                  : banner.media_url_web_desk_thumb
                              }
                              fullImageSrc={
                                isMobile
                                  ? banner.media_url_web_mobile
                                  : banner.media_url_web_desk
                              }
                              alt="Store Image"
                              className="carousel-image"
                            />
                          </div>
                        ))}
                      </OwlCarousel>
                    )
                  )}
                </div>
              </div>
            </section>
          )}

          {data.latest_arrival && (
            <LatestArrivals products={data.latest_arrival} />
          )}
        </>
      )}

      <Modal
        isOpen={!!selectedVideo}
        onClose={closeModal}
        videoUrl={selectedVideo}
        videoData={selectedProductLinks}
      />
    </div>
  );
};

export default HomePage;
