import { useEffect } from "react";

// Declare global variables to avoid ESLint warnings
/* global dataLayer, fbq */

const useDynamicMetaTags = () => {
  useEffect(() => {
    const getCountryCodeByDomain = () => {
      const hostname = window.location.hostname;
      const port = window.location.port;

      if (hostname === "fashionxglobal.com" || port === "3001") {
        return "US";
      } else if (hostname === "modestrend.com" || port === "3002") {
        return "TR";
      } else if (hostname === "asianethnics.com" || port === "3003") {
        return "IN";
      } else {
        return "US";
      }
    };

    const countryCode = getCountryCodeByDomain();
    const hostname = window.location.hostname;
    const port = window.location.port;

    // Set dynamic title based on the hostname and port
    const titles = {
      "fashionxglobal.com": "FashionX Global - Stylish Fashion",
      "modestrend.com": "Modestrend - Modesty with Style",
      "asianethnics.com": "Asian Ethnics - Traditional Wear",
      3001: "FashionX Global - Stylish Fashion (Port 3001)",
      3002: "Modestrend - Modesty with Style (Port 3002)",
      3003: "Asian Ethnics - Traditional Wear (Port 3003)",
      default: "Welcome to Our Fashion Store",
    };

    document.title = titles[hostname] || titles[port] || titles["default"];

    // Change meta description dynamically
    const descriptions = {
      "fashionxglobal.com": "Global fashion trends at your fingertips.",
      "modestrend.com": "Modesty with style - Elegant and modest fashion.",
      "asianethnics.com": "Discover the beauty of ethnic fashion.",
      3001: "FashionX Global - Stylish Fashion (Port 3001)",
      3002: "Modestrend - Modesty with Style (Port 3002)",
      3003: "Asian Ethnics - Traditional Wear (Port 3003)",
      default: "The best fashion for everyone.",
    };

    const descriptionMetaTag = document.querySelector(
      'meta[name="description"]'
    );
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute(
        "content",
        descriptions[hostname] || descriptions[port] || descriptions["default"]
      );
    }

    // Dynamic meta keywords for all three sites
    const keywords = {
      "fashionxglobal.com":
        "fashion, trends, global fashion, stylish wear, modern fashion",
      "modestrend.com":
        "modesty, fashion, hijab, abaya, modest clothing, modest fashion",
      "asianethnics.com":
        "ethnic wear, traditional clothing, cultural fashion, ethnic fashion, sarees, lehengas",
      3001: "fashion, trends, global fashion, stylish wear, modern fashion (Port 3001)",
      3002: "modesty, fashion, hijab, abaya, modest clothing, modest fashion (Port 3002)",
      3003: "ethnic wear, traditional clothing, cultural fashion, ethnic fashion, sarees, lehengas (Port 3003)",
      default: "fashion, modesty, clothing, trends, stylish",
    };

    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute(
        "content",
        keywords[hostname] || keywords[port] || keywords["default"]
      );
    }

    // Dynamically set the Facebook Pixel and Google Analytics based on website (hostname or port)
    if (hostname === "fashionxglobal.com" || port === "3001") {
      addAnalyticsScripts("US");
    } else if (hostname === "modestrend.com" || port === "3002") {
      addAnalyticsScripts("TR");
    } else if (hostname === "asianethnics.com" || port === "3003") {
      addAnalyticsScripts("IN");
    }

    // Dynamically set Facebook Domain Verification Meta Tag
    const fbVerificationTag = document.querySelector(
      'meta[name="facebook-domain-verification"]'
    );
    if (fbVerificationTag) {
      fbVerificationTag.setAttribute(
        "content",
        "1xkhz9k855fo0450m0yj5l22y1g6bt"
      ); // Example value
    }
  }, []);

  const addAnalyticsScripts = (countryCode) => {
    // Google Analytics
    const scriptGA = document.createElement("script");
    scriptGA.async = true;
    scriptGA.src = `https://www.googletagmanager.com/gtag/js?id=G-${
      countryCode === "US"
        ? "US123456"
        : countryCode === "TR"
        ? "TR123456"
        : "IN123456"
    }`;
    document.head.appendChild(scriptGA);

    scriptGA.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag(
        "config",
        `G-${
          countryCode === "US"
            ? "US123456"
            : countryCode === "TR"
            ? "TR123456"
            : "IN123456"
        }`
      );
    };

    // Facebook Pixel (as an example)
    const scriptFB = document.createElement("script");
    scriptFB.src = "https://connect.facebook.net/en_US/fbevents.js";
    document.head.appendChild(scriptFB);

    scriptFB.onload = () => {
      fbq("init", "1084539896401020");
      fbq("track", "PageView");
    };

    const noscriptTag = document.createElement("noscript");
    noscriptTag.innerHTML = `<img height="1" width="1" src="https://www.facebook.com/tr?id=1084539896401020&ev=PageView&noscript=1" />`;
    document.body.appendChild(noscriptTag);
  };
};

export default useDynamicMetaTags;
